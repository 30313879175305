import NavigationDesktopItemsStyle from "@/components/navigation/navigationDesktop/navigationDesktopItemsStyle";
import { PageElement } from "@/types/navigation";
import {
  getPageUrlOrRootUrl,
  isPageElementActive
} from "@/utils/urlUtil";
import { navigationLinkStyleByLevel } from "@/utils/util";
import Container from "@mui/material/Container";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";

interface NavigationDesktopItemsProps {
  pages: PageElement[];
  allItems?: boolean;
}

export default function NavigationDesktopItems(
  props: NavigationDesktopItemsProps
) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState<PageElement[]>([]);
  const router = useRouter();

  const updateSubMenuContent = (page: PageElement, openDrawer = true) => {
    const newContent = props.allItems ? props.pages : [page];
    setDrawerContent(newContent);
    setDrawerOpen(
      !(newContent.length === 1 && newContent[0].subPages.length === 0)
    );
  };

  const renderInternalOrExternalLink = (
    page: PageElement,
    className?: string
  ) => {
    const isNavigationLinkActive = isPageElementActive(router, page);
    if (page.externalUrl.trim().length > 0) {
      return (
        <a
          className={clsx("navigation-link", className)}
          target="_blank"
          href={page.externalUrl}
          rel="nofollow noopener noreferrer"
        >
          {page.name}
        </a>
      );
    }

    return (
      <Link
        href={`${getPageUrlOrRootUrl(page.page.url)}${
          page.anchor ? `#${page.anchor}` : ""
        }`}
        passHref
        className={clsx(
          "navigation-link",
          isNavigationLinkActive && "active",
          className
        )}
      >
        {page.name}
      </Link>
    );
  };

  const renderSubLevel = (page: PageElement, level: number, index: number) => {
    if (!page.page?.published && page.externalUrl.length === 0) {
      return null;
    }
    return (
      <div
        key={`${index}-level-${level}`}
        className={clsx("sub-navigation-item", `level-${level}`)}
      >
        {renderInternalOrExternalLink(page, navigationLinkStyleByLevel(level))}
        {page.subPages &&
          page.subPages.map((subPage, innerIndex) => {
            return renderSubLevel(subPage, level + 1, innerIndex * 100);
          })}
      </div>
    );
  };

  return (
    <>
      <ul
        className="navigation-desktop-top-level-menu"
        onMouseLeave={() => setDrawerOpen(false)}
      >
        {props.pages.map((page, index) => {
          return (
            <li
              className="navigation-item"
              key={"desktop-" + page.name + page.id + index}
              onFocus={() => updateSubMenuContent(page)}
              onMouseEnter={() => updateSubMenuContent(page)}
            >
              {renderInternalOrExternalLink(
                page,
                navigationLinkStyleByLevel(1)
              )}
            </li>
          );
        })}
      </ul>
      <div
        className={clsx(
          !drawerOpen && "hidden",
          "navigation-desktop-submenu-drawer"
        )}
        onMouseEnter={() => setDrawerOpen(true)}
        onMouseLeave={() => setDrawerOpen(false)}
      >
        <Container maxWidth="xl">
          <div className="navigation-desktop-submenu-drawer-connector"></div>
          <div className="navigation-desktop-submenu-drawer-content">
            {drawerContent.map((page) => {
              if (page.subPages.length === 0) {
                return null;
              }
              return (
                <div
                  key={`${page.id}-col`}
                  className="navigation-desktop-submenu-drawer-content-col"
                >
                  {page.subPages.map((page, index) => {
                    return renderSubLevel(page, 2, index);
                  })}
                </div>
              );
            })}
          </div>
        </Container>
      </div>
      <NavigationDesktopItemsStyle />
    </>
  );
}
